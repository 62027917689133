.teaser {
		display: flex;
		padding: 1rem var(--spacer-m) 0;
		background-image: linear-gradient(to right bottom,
		var(--main-color) 50%,
		var(--background-color) 50%);
}

.teaser .tagline {
		flex-basis: 50%;
		line-height: var(--tight-line-height);
		font-size: var(--tagline-font-size);
		color: var(--background-color);
}

.teaser .logo {
		flex-basis: 70%;
}

.teaser  .logo video {
		cursor: pointer;
}
