/* 
  Syntax Highlighting for Code Snippets
  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

	Based on Twilight:
	https://gist.github.com/dansimpson/803005
*/

pre.highlight {
		color: white;
		background: hsl(0, 0%, 8%);
		font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
		font-size: 1.3em;
		text-align: left;
		text-shadow: 0 -.1em .2em black;
		white-space: pre;
		word-spacing: normal;
		word-break: normal;
		word-wrap: normal;
		line-height: 1.5;
		tab-size: 4;
		hyphens: none;

		border-radius: var(--base-border-radius);
		margin: var(--base-block-margin);
		overflow: auto;
		padding: 1em;
}

pre.highlight .hll { background-color: #ffffcc }
pre.highlight .c { color: #5F5A60; font-style: italic } /* Comment */
pre.highlight .err { border:#B22518; } /* Error */
pre.highlight .k { color: #CDA869 } /* Keyword */
pre.highlight .cm { color: #5F5A60; font-style: italic } /* Comment.Multiline */
pre.highlight .cp { color: #5F5A60 } /* Comment.Preproc */
pre.highlight .c1 { color: #5F5A60; font-style: italic } /* Comment.Single */
pre.highlight .cs { color: #5F5A60; font-style: italic } /* Comment.Special */
pre.highlight .gd { background: #420E09 } /* Generic.Deleted */
pre.highlight .ge { font-style: italic } /* Generic.Emph */
pre.highlight .gr { background: #B22518 } /* Generic.Error */
pre.highlight .gh { color: #000080; font-weight: bold } /* Generic.Heading */
pre.highlight .gi { background: #253B22 } /* Generic.Inserted */
pre.highlight .gp { font-weight: bold } /* Generic.Prompt */
pre.highlight .gs { font-weight: bold } /* Generic.Strong */
pre.highlight .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
pre.highlight .kd { color: #e9df8f;  } /* Keyword.Declaration */
pre.highlight .kp { color: #9B703F } /* Keyword.Pseudo */
pre.highlight .na { color: #F9EE98 } /* Name.Attribute */
pre.highlight .nb { color: #CDA869 } /* Name.Builtin */
pre.highlight .nc { color: #9B859D; font-weight: bold } /* Name.Class */
pre.highlight .no { color: #9B859D } /* Name.Constant */
pre.highlight .nd { color: #7587A6 } /* Name.Decorator */
pre.highlight .ni { color: #CF6A4C; font-weight: bold } /* Name.Entity */
pre.highlight .nf { color: #9B703F; font-weight: bold } /* Name.Function */
pre.highlight .nn { color: #9B859D; font-weight: bold } /* Name.Namespace */
pre.highlight .nt { color: #CDA869; font-weight: bold } /* Name.Tag */
pre.highlight .nv { color: #7587A6 } /* Name.Variable */
pre.highlight .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
pre.highlight .w { color: #141414 } /* Text.Whitespace */
pre.highlight .mf { color: #CF6A4C } /* Literal.Number.Float */
pre.highlight .mh { color: #CF6A4C } /* Literal.Number.Hex */
pre.highlight .mi { color: #CF6A4C } /* Literal.Number.Integer */
pre.highlight .mo { color: #CF6A4C } /* Literal.Number.Oct */
pre.highlight .sb { color: #8F9D6A } /* Literal.String.Backtick */
pre.highlight .sc { color: #8F9D6A } /* Literal.String.Char */
pre.highlight .sd { color: #8F9D6A; font-style: italic; } /* Literal.String.Doc */
pre.highlight .s2 { color: #8F9D6A } /* Literal.String.Double */
pre.highlight .se { color: #F9EE98; font-weight: bold; } /* Literal.String.Escape */
pre.highlight .sh { color: #8F9D6A } /* Literal.String.Heredoc */
pre.highlight .si { color: #DAEFA3; font-weight: bold; } /* Literal.String.Interpol */
pre.highlight .sx { color: #8F9D6A } /* Literal.String.Other */
pre.highlight .sr { color: #E9C062 } /* Literal.String.Regex */
pre.highlight .s1 { color: #8F9D6A } /* Literal.String.Single */
pre.highlight .ss { color: #CF6A4C } /* Literal.String.Symbol */
pre.highlight .bp { color: #00aaaa } /* Name.Builtin.Pseudo */
pre.highlight .vc { color: #7587A6 } /* Name.Variable.Class */
pre.highlight .vg { color: #7587A6 } /* Name.Variable.Global */
pre.highlight .vi { color: #7587A6 } /* Name.Variable.Instance */
pre.highlight .il { color: #009999 } /* Literal.Number.Integer.Long */
